<template>

    <div>
     <template>
          <div id="breadcrumb-alignment" class="mb-1">
            <div class="d-flex justify-content-start breadcrumb-wrapper">
              <b-breadcrumb
                :items="breadCrumb()"
              />
            </div>
          </div>
        </template>
        <b-card no-body>
            <b-card-body>
                <b-row>
                    <b-col cols="12" md="3" class="mb-md-0 mb-2 mobile_tab_max_width_flex mobile_tab_margin_bottom_point_five">
                    
                        <!-- <v-select
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="sites"
                            label="site_name"
                            class="w-100"
                            placeholder="Project Site"
                            v-model="siteData"
                            @input="filterTable"
                        /> -->

                        <b-form-select v-model="siteData" @change="filterTable(); updateDateFormat();">

                          <b-form-select-option value="" disabled>Select</b-form-select-option>
                          
                          <b-form-select-option :value="site._id" v-for="site in sites" :key="site._id">{{site.site_name | capitalize}}</b-form-select-option>
                          
                          
                        </b-form-select>
                    </b-col>

                    <b-col cols="12" md="3" class="mb-md-0 mb-2 mobile_tab_max_width_flex mobile_tab_margin_bottom_point_five">
                        <b-form-datepicker v-model="start" :date-format-options="{ day: 'numeric', month: 'short',year: 'numeric' }" locale="en" @input="updateStart()"/>
                    </b-col>

                    <b-col cols="12" md="3" class="mb-md-0 mb-2 mobile_tab_max_width_flex mobile_tab_margin_bottom_point_five">
                        <b-form-datepicker @change="filterTable" v-model="end" :date-format-options="{ day: 'numeric', month: 'short',year: 'numeric' }" locale="en" @input="filterTable" :min="disabledDates()"/>
                    </b-col>

                    <b-col cols="12" md="3" class="mb-md-0 mobile_tab_max_width_flex">
                      <b-form-select v-model="vuser" @change="filterTable()">
                        <b-form-select-option value="" disabled>Validator</b-form-select-option>
                        <b-form-select-option :value="vuser.key" v-for="(vuser,index) in vusers" :key="index">{{vuser.name | capitalize}}</b-form-select-option>
                      </b-form-select>
                    </b-col>

                </b-row>
            </b-card-body>
        </b-card>
        <!-- Table Container Card -->
        <b-card no-body class="mb-0">

            <div class="m-2">
                <!-- Table Top -->
                <b-row>
                    <!-- Per Page -->
                    <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0 mobile_tab_max_width_flex mobile_margin_bottom_1rem">
                        <label>Show</label>
                        <v-select
                            v-model="perPage"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="perPageOptions"
                            :clearable="false"
                            class="per-page-selector d-inline-block mx-50"
                            @input="filterTable"
                        />
                        <label>entries</label>
                    </b-col>

                    <!-- Search -->
                    <b-col cols="12" md="6" class="mobile_tab_max_width_flex all_btn_tab">
                        <div class="d-flex align-items-center justify-content-end mobile_tab_display_block">
                            <b-form-input
                                v-model="searchQuery"
                                class="d-inline-block mr-1 mobile_margin_bottom_1rem"
                                placeholder="Search..."
                                @input="filterTable"
                            />
                            <b-button
                              v-if="checkPermission('custom-forms-dashboard', 'Zip Download')"
                              :disabled="selected && selected.length > 0 ? false:true"
                              variant="primary"
                              class=" "
                              @click="zipDownload()"
                            >
                              <span class="text-nowrap">Zip Download</span>
                            </b-button>

                            <!-- <b-button
                              v-if="checkPermission('custom-forms-dashboard', 'Delete')"
                              :disabled="selected && selected.length > 0 ? false:true"
                              variant="danger"
                              class="mr-1 mobile-margin-bottom"
                              @click="multipleDelete()"
                            >
                              <span class="text-nowrap">Delete</span>
                            </b-button> -->
                        </div>
                    </b-col>

                </b-row>
            </div>

            <b-table
                responsive
                show-empty
                ref = "refUserListTable"
                class = "position-relative midTableRpt common_big_tbl"
                primary-key = "id"
                empty-text = "No matching records found"
                :fields = "checkPermission('custom-forms-dashboard', 'Zip Download') ? tableColumns2 : tableColumns"
                :sort-by.sync = "sortBy"
                :items = "dataList"
                :sort-desc.sync = "isSortDirDesc"
                :class="items.length <= 10 ? 'remove_overflow' : 'add_overflow'"
            >
                <template #head(checkbox)="items">
                  <b-form-checkbox class="custom-control-warning" v-model="selectAll" @change="getAll"/>
                </template>

                <template #cell(checkbox)="items">
                      <!-- v-if="checkPermission('custom-forms-dashboard', 'Delete') && ((items.item.level_user_status != null) && (items.item.level_user_status.role == $store.getters.currentUser.role))" -->
                    <b-form-checkbox 
                      v-model="selected" 
                      :value="items.item._id" 
                      class="custom-control-warning my-1"
                    />
                </template>

                 <template #cell(info)="items">             
                      <feather-icon
                      :icon="items.detailsShowing ? 'EyeIcon' : 'EyeOffIcon'"
                      @click="items.toggleDetails"
                      />
                </template>

                <template #cell(unique_id)="items">
                    <span class="wordBreak" @click="items.toggleDetails">{{ items.item.unique_id | capitalize}}</span>
                </template>

                <template #cell(site_name)="items">
                    <span class="wordBreak" @click="items.toggleDetails">{{ items.item.site.site_name | capitalize}}</span>
                </template>

                <template #cell(shift)="items">
                    <span class="wordBreak" @click="items.toggleDetails">{{ items.item.shift.shift | capitalize}}</span>
                </template>

                <!-- <template #cell(building)="items">
                    <span v-if="getBuilding(items.item.checkListData).length > 50" v-b-tooltip.hover.v-primary.top :title="getBuilding(items.item.checkListData)" class="wordBreak">{{getBuilding(items.item.checkListData) | truncate}}</span>
                    <span v-else class="wordBreak">{{getBuilding(items.item.checkListData)}}</span>
                </template> -->

                <template #cell(checked_by)="items">
                    <span class="wordBreak" @click="items.toggleDetails">{{ items.item.checked_by | capitalize}}</span>
                </template>

                <!-- <template #cell(checked_date)="items"> -->
                    <!-- <span>{{items.item.checked_date | date}}</span> -->
                    <!-- <span>{{sitedateTime(items.item.checked_date,date_format,'' )}}</span> -->
                <!-- </template> -->

                <!-- <template #cell(reject_comment)="items">
                    <span v-if="items.item.reject_comment && items.item.reject_comment.length > 50" v-b-tooltip.hover.v-primary.top :title="items.item.reject_comment" class="wordBreak">{{items.item.reject_comment | capitalize | truncate}}</span>
                    <span v-else class="wordBreak">{{items.item.reject_comment | capitalize}}</span>
                </template> -->

                <template #cell(status)="items">
                    
                    
                    <div>

                        <b-badge @click="items.toggleDetails"
                          pill
                          variant="danger"
                          class="text-capitalize"
                          v-if="(items.item.reject_to != null)"
                        >
                          <!-- {{ 'L1 Pending'}} -->
                          <span>
                            {{ 'Rejected - '+ items.item.reject_by | removeUnderCapitalize}}
                          </span>
                          
                        </b-badge>

                        <b-badge @click="items.toggleDetails"
                          pill
                          variant="warning"
                          class="text-capitalize"
                          v-else-if="(items.item.level_one_user_signature == null && items.item.level_user_status != null)"
                        >
                          <!-- {{ 'L1 Pending'}} -->
                          <span v-if="items.item.level_user_status.role == $store.getters.currentUser.role">
                            {{ 'Pending' | removeUnderCapitalize}}
                          </span>
                          <span v-else>
                            {{ 'Pending - '+ items.item.level_one_user_role | removeUnderCapitalize}}
                          </span>
                        </b-badge>


                        <b-badge @click="items.toggleDetails"
                          pill
                          variant="warning"
                          class="text-capitalize"
                          v-else-if="(items.item.level_user_status != null) && (items.item.level_one_user_signature != null) && (items.item.level_two_user_signature == null)"
                        >
                          <!-- {{ 'L2 Pending'}} -->
                         

                          <span v-if="items.item.level_user_status.role == $store.getters.currentUser.role">
                            {{ 'Pending' | removeUnderCapitalize}}
                          </span>
                          <span v-else>
                            {{ 'Pending - '+ items.item.level_two_user_role | removeUnderCapitalize}}
                          </span>

                        </b-badge>

                        <b-badge @click="items.toggleDetails"
                          pill
                          variant="warning"
                          class="text-capitalize"
                          v-else-if="(items.item.level_user_status != null) && (items.item.level_two_user_signature != null)"
                        >
                          <!-- {{ 'L2 Pending'}} -->
                         
                          <span v-if="items.item.level_user_status.role == $store.getters.currentUser.role">
                            {{ 'Pending' | removeUnderCapitalize}}
                          </span>
                          <span v-else>
                            {{ 'Pending - '+ items.item.level_three_user_role | removeUnderCapitalize}}
                          </span>

                        </b-badge>

                        <b-badge @click="items.toggleDetails"
                          pill
                          variant="success"
                          class="text-capitalize"
                          v-else
                        >
                          {{ 'Completed'}}
                        </b-badge>

                    </div>
                    
                 
                </template>

                <!-- <template #cell(level_one_user_remarks)="items">                    
                    <span v-b-tooltip.hover.v-primary.top :title="items.item.level_one_user.role + ' - ' +items.item.level_one_user_remarks | removeUnderCapitalize" class="wordBreak" v-if="(items.item.level_one_user_remarks != null && items.item.level_one_user_remarks != '') && items.item.level_one_user_remarks.length > 50">{{items.item.level_one_user.role | removeUnderCapitalize}} - {{items.item.level_one_user_remarks | capitalize | truncate}}</span>
                    <span class="wordBreak" v-else-if="(items.item.level_one_user_remarks != null && items.item.level_one_user_remarks != '') && items.item.level_one_user_remarks.length <= 50">{{items.item.level_one_user.role | removeUnderCapitalize}} - {{items.item.level_one_user_remarks | capitalize | truncate}}</span>
                    <span class="wordBreak" v-else>{{'N/A'}}</span>
                </template> -->

                <!-- <template #cell(level_two_user_remarks)="items">                    
                    <span v-b-tooltip.hover.v-primary.top :title="items.item.level_two_user.role + ' - ' +items.item.level_two_user_remarks | removeUnderCapitalize" class="wordBreak" v-if="(items.item.level_two_user_remarks != null && items.item.level_two_user_remarks != '') && items.item.level_two_user_remarks.length > 50">{{items.item.level_two_user.role | removeUnderCapitalize}} - {{items.item.level_two_user_remarks | capitalize | truncate}}</span>
                    <span class="wordBreak" v-else-if="(items.item.level_two_user_remarks != null && items.item.level_two_user_remarks != '') && items.item.level_two_user_remarks.length <= 50">{{items.item.level_two_user.role | removeUnderCapitalize}} - {{items.item.level_two_user_remarks | capitalize | truncate}}</span>
                    <span class="wordBreak" v-else>{{'N/A'}}</span>
                </template> -->

                <!-- <template #cell(level_three_user_remarks)="items">                    
                    <span v-b-tooltip.hover.v-primary.top :title="items.item.level_three_user.role + ' - ' +items.item.level_three_user_remarks | removeUnderCapitalize" class="wordBreak" v-if="(items.item.level_three_user_remarks != null && items.item.level_three_user_remarks != '') && items.item.level_three_user_remarks.length > 50">{{items.item.level_three_user.role | removeUnderCapitalize}} - {{items.item.level_three_user_remarks | capitalize | truncate}}</span>
                    <span class="wordBreak" v-else-if="(items.item.level_three_user_remarks != null && items.item.level_three_user_remarks != '') && items.item.level_three_user_remarks.length <= 50">{{items.item.level_three_user.role | removeUnderCapitalize}} - {{items.item.level_three_user_remarks | capitalize | truncate}}</span>
                    <span class="wordBreak" v-else>{{'N/A'}}</span>
                </template> -->

                <template #cell(actions)="items">
                    <b-row>
                        <div class="p-0 pb-1 mobile_col col-4" v-if="checkPermission('custom-forms-dashboard', 'Delete') && ((items.item.level_user_status != null) && ((items.item.level_user_status.role == $store.getters.currentUser.role) || ($store.getters.currentUser.role == 'administrator') ))">
                            <b-link
                              v-if="checkPermission('custom-forms-dashboard', 'Delete') && ((items.item.level_user_status != null) && ((items.item.level_user_status.role == $store.getters.currentUser.role) || ($store.getters.currentUser.role == 'administrator') ))"
                              v-b-tooltip.hover.v-warning
                              title="Delete"
                              variant="outline-warning"
                              @click="deleteItem(items.item._id)"
                            >
                              <feather-icon icon="Trash2Icon" class="mr-1 mediumSize"/>
                                
                          </b-link>
                        </div>
                        <div class="p-0 pb-1 mobile_col col-4" v-if="checkPermission('custom-forms-dashboard', 'Download')">
                                <b-link
                                v-if="checkPermission('custom-forms-dashboard', 'Download')"
                                v-b-tooltip.hover.v-warning
                                title="Download"
                                variant="outline-warning"
                                @click="download(items.item.pdf)"
                            >
                                <feather-icon icon="DownloadIcon" class="mr-1 mediumSize"/>
                            </b-link>
                        </div>
                        <div class="p-0 pb-1 mobile_col col-4" v-if="(((items.item.level_user_status != null) && ((items.item.level_user_status.role == $store.getters.currentUser.role) || ($store.getters.currentUser.role == 'administrator')) && ($store.getters.currentUser.role != 'site_manager') && ($store.getters.currentUser.role != 'site_client')) || ((items.item.reject_to == $store.getters.currentUser.role) && ($store.getters.currentUser.role != 'site_manager') && ($store.getters.currentUser.role != 'site_client')))">
                            <b-link
                              v-if="(((items.item.level_user_status != null) && ((items.item.level_user_status.role == $store.getters.currentUser.role) || ($store.getters.currentUser.role == 'administrator')) && ($store.getters.currentUser.role != 'site_manager') && ($store.getters.currentUser.role != 'site_client')) || ((items.item.reject_to == $store.getters.currentUser.role) && ($store.getters.currentUser.role != 'site_manager') && ($store.getters.currentUser.role != 'site_client')))"
                              v-b-tooltip.hover.v-warning
                              title="Edit"
                              variant="outline-warning"
                              :to="{name:'staircase-hand-sanitizer',params:{id:items.item._id}}"
                          >
                              <feather-icon icon="EditIcon" class="mr-1 mediumSize"/>
                          </b-link>
                        </div>
                        <div class="p-0 pb-1 mobile_col col-4" v-if="((items.item.level_user_status != null) && ( (items.item.level_user_status.role == $store.getters.currentUser.role) || ($store.getters.currentUser.role == 'administrator') ) && items.item.reject_comment == null)">
                            <b-link
                              v-if="((items.item.level_user_status != null) && ( (items.item.level_user_status.role == $store.getters.currentUser.role) || ($store.getters.currentUser.role == 'administrator') ) && items.item.reject_comment == null)"
                              v-b-tooltip.hover.v-warning
                              title="Sign"
                              variant="outline-warning"
                              @click="openSignature(items.item._id,items.item.checked_date)"
                          >
                              <feather-icon icon="CheckCircleIcon" class="mr-1 mediumSize"/>
                          </b-link>
                        </div>
                        <div class="p-0 pb-1 mobile_col col-4" v-if="((items.item.level_user_status != null) && ( (items.item.level_user_status.role == $store.getters.currentUser.role) || ($store.getters.currentUser.role == 'administrator') ) && allowReject(items.item))">
                             <b-link
                              v-if="((items.item.level_user_status != null) && ( (items.item.level_user_status.role == $store.getters.currentUser.role) || ($store.getters.currentUser.role == 'administrator') ) && allowReject(items.item))"
                              v-b-tooltip.hover.v-warning
                              title="Reject"
                              variant="outline-warning"
                              @click="openRejectBox(items.item)"
                          >
                              <feather-icon icon="SlashIcon" class="mr-1 mediumSize"/>
                          </b-link>
                        </div>
                      </b-row>
                  </template>

                <template #row-details="items">

                    <div class="p-1 px-2 fbDetail mb-1">
                      <ReportViewComponent v-bind:item="items.item" v-bind:form_type="'Staircase Hand Sanitizer'" v-bind:date_format="date_format"/>
                    </div>

                  <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-secondary"
                    @click="items.toggleDetails"
                    size="sm"
                    class=""
                  >
                    Hide Detail
                  </b-button>
                  

              </template>

            </b-table>

            <div class="mx-2 mb-2">
                <b-row>

                    <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
                        <span class="text-muted">Showing {{ from }} to {{ to }} of {{ totalRecords }}</span>
                    </b-col>

                    <!-- Pagination -->
                    <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
                        <b-pagination
                        v-model="currentPage"
                        :total-rows="totalRecords"
                        :per-page="perPage"
                        first-number
                        last-number
                        class="mb-0 mt-1 mt-sm-0"
                        prev-class="prev-item"
                        next-class="next-item"
                        @input="filterTable"
                        >
                            <template #prev-text>
                                <feather-icon icon="ChevronLeftIcon" size="18"/>
                            </template>
                            <template #next-text>
                                <feather-icon icon="ChevronRightIcon" size="18"/>
                            </template>
                        </b-pagination>
                    </b-col>

                </b-row>
            </div>

        </b-card>
        <b-modal
          id="signatureModel"
          ref="signatureModel"
          centered
          title="Signature"
          no-close-on-backdrop
          hide-footer
          @hide="hideSignature"
          @show="hideSignature"
        >
          
          <div v-if="show_default == true">
            <b-row>

              <b-col md="11" style="background: #ffffff; height:300px; vertical-align: middle;margin-left: 4%;margin-right:2%">
                <center>
                  <img :src="tempSign.image" width="240px" height="200px" style="background: #ffffff; border-radius: 4px;margin-top: 11%">
                </center>

              </b-col>
            </b-row>
              <b-row class="mt-2">
          
              <b-col md="12">
                <date-picker v-model="approve_signature_time" 
                  format="DD MMM YYYY HH:mm" 
                  type="datetime"
                  placeholder="Select"
                  valueType="format"
                  :clearable=false
                  :confirm=true
                  lang="en"
                  :class="$store.state.appConfig.layout.skin == 'dark' ? 'darkDatePicker' : 'lightDatePicker'"
                  :disabled="$store.getters.currentUser.role == 'supervisor' || $store.getters.currentUser.role == 'operation_manager' || $store.getters.currentUser.role == 'administrator' ? false : true"
                  :disabled-date="disabledDate"
                  ></date-picker>


              </b-col>
            </b-row>

            <b-row class="mt-2">
          
              <b-col md="12">
                <b-form-group
                    label="Remarks"
                    class=""
                  >
                    <b-form-textarea
                      placeholder=""
                      rows="3"
                      v-model="approve_remark"
                    />
                  </b-form-group>
              </b-col>
            </b-row>

            <b-row class="mt-2">

              <b-col md="6">

                  <b-button
                      variant="secondary"
                      class="mt-0 w-100"
                      @click="show_default = false"
                    >
                    <span class="text-nowrap">Add New Signature</span>
                  </b-button>
                </b-col>

                <b-col md="6">
                  <b-button
                      variant="warning"
                      class="mt-0 float-right w-100"
                      @click="saveDefault()"
                    >
                    <span class="text-nowrap">Submit</span>
                  </b-button>
                </b-col>
            </b-row>
          </div>

          <div v-else>
            <b-row>
              <b-col md="12">
                <VueSignaturePad
                  id="signature"
                  width="100%"
                  height="300px"
                  ref="signaturePad"
                  :options="{onBegin: () => {$refs.signaturePad.resizeCanvas()},penColor: '#000000',minWidth: 3,maxWidth: 3}"
                />

              </b-col>
            </b-row>
            <b-row class="mt-2">

              <b-col md="4" v-if="having_default == true">

                  <b-button
                      variant="secondary"
                      class="mt-0 w-100"
                      @click="show_default = true"
                    >
                    <span class="text-nowrap">Default</span>
                  </b-button>
                </b-col>


              <b-col :md="having_default == true ? '4' : '6'">

                  <b-button
                      variant="danger"
                      class="mt-0 w-100"
                      @click="clearSignature"
                    >
                    <span class="text-nowrap">Clear</span>
                  </b-button>
                </b-col>

                <b-col md="4" :md="having_default == true ? '4' : '6'">
                  <b-button
                      variant="warning"
                      class="mt-0 w-100"
                      @click="saveSignature"
                    >
                    <span class="text-nowrap">Submit</span>
                  </b-button>
                </b-col>
            </b-row>
          </div>
          </b-modal>

          <b-modal
            id="rejectbox"
            ref="rejectbox"
            cancel-variant="outline-secondary"
            ok-variant="outline-warning"
            ok-title="Submit"
            cancel-title="Close"
            centered
            title="Reject Report"
            no-close-on-backdrop
            @ok="markReject"
            >

            <b-alert
              v-model="showDismissibleAlert"
              variant="danger"
              dismissible
              class="mb-1"
            >
              <div class="alert-body">
                {{error_message}}
              </div>
            </b-alert>
            <b-form>
              <b-row>
                <b-col md="12">
                  <b-form-group
                    label="Reason"
                    class="required"
                  >
                    <b-form-textarea
                      placeholder="3 to 500 characters"
                      rows="3"
                      v-model="comment"
                    />
                  </b-form-group>
                </b-col> 
              </b-row>
            </b-form>

        </b-modal>
    </div>
</template>

<script>
    import {
        BCardBody, BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink, BBadge, BDropdown, BDropdownItem, BPagination, VBTooltip, BFormDatepicker,BFormSelect,BFormSelectOption,BBreadcrumb,BFormTextarea,BForm,BFormGroup,BAlert,BFormCheckbox
    } from 'bootstrap-vue'
    import vSelect from 'vue-select'
    import store from '@/store'
    import { ref, onUnmounted } from '@vue/composition-api'
    import { POST_API } from "../../../store/actions.type"
    import moment from "moment";
    import Bus from "../../../event-bus";
    import DatePicker from 'vue2-datepicker';
    import 'vue2-datepicker/index.css';
    import ReportViewComponent from './../ReportViewComponent.vue';
    import Ripple from 'vue-ripple-directive';

    export default {
        components: {
            BCardBody, BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink, BBadge, BDropdown, BDropdownItem, BPagination, vSelect, BFormDatepicker,BFormSelect,BFormSelectOption,BBreadcrumb, DatePicker,BFormTextarea,BForm,BFormGroup,BAlert,BFormCheckbox,ReportViewComponent
        },
        directives: {
            'b-tooltip': VBTooltip, Ripple
        },
        data() {
            return {
                date_format:'DD MMM YYYY',
                time_format:'HH:mm',
                webUrl: process.env.VUE_APP_SERVER_URL,
                error_message: null, 
                showDismissibleAlert:false,
                tableColumns: [
                    { key: 'info', label:'INFO',sortable: false , thStyle:  {width: '1%'}},
                    { key: 'unique_id', label: 'ID', sortable: true , thStyle:  {width: '20%'}},
                    { key: 'site_name', label: 'Project Site Name', sortable: false , thStyle:  {width: '15%'}},
                    
                    { key: 'shift', label: 'Shift', sortable: false , thStyle:  {width: '15%'}},
                    
                    // { key: 'building', label: 'Building', sortable: false , thStyle:  {width: '8%'}},
                    
                    { key: 'checked_by', label: 'Checked By', sortable: true , thStyle:  {width: '15%'}},
                    // { key: 'checked_date', label: 'Checked Date', sortable: true , thStyle:  {width: '8%'}},
                    
                    // { key: 'level_one_user_remarks', label: 'Level 1 Remarks', sortable: true , thStyle:  {width: '8%'}},
                    // { key: 'level_two_user_remarks', label: 'Level 2 Remarks', sortable: true , thStyle:  {width: '8%'}},
                    // { key: 'level_three_user_remarks', label: 'Level 3 Remarks', sortable: true , thStyle:  {width: '8%'}},
                    
                    // { key: 'reject_comment', label: 'Reject Reason', sortable: true , thStyle:  {width: '8%'}},
                    { key: 'status', label: 'Status', sortable: false ,thClass:'alignCenter',tdClass: 'alignCenter', thStyle:  {width: '18%'}},
                    { key: 'actions', label: 'Action', sortable: false , thStyle:  {width: '16%'}},
                ],
                tableColumns2: [
                    { key: 'checkbox', label:'',sortable: false , thStyle:  {width: '1%'}},
                    { key: 'info', label:'INFO',sortable: false , thStyle:  {width: '1%'}},
                    { key: 'unique_id', label: 'ID', sortable: true , thStyle:  {width: '19%'}},
                    { key: 'site_name', label: 'Project Site Name', sortable: false , thStyle:  {width: '15%'}},
                    
                    { key: 'shift', label: 'Shift', sortable: false , thStyle:  {width: '15%'}},
                    
                    // { key: 'building', label: 'Building', sortable: false , thStyle:  {width: '8%'}},
                    
                    { key: 'checked_by', label: 'Checked By', sortable: true , thStyle:  {width: '15%'}},
                    // { key: 'checked_date', label: 'Checked Date', sortable: true , thStyle:  {width: '7%'}},
                    
                    // { key: 'level_one_user_remarks', label: 'Level 1 Remarks', sortable: true , thStyle:  {width: '8%'}},
                    // { key: 'level_two_user_remarks', label: 'Level 2 Remarks', sortable: true , thStyle:  {width: '8%'}},
                    // { key: 'level_three_user_remarks', label: 'Level 3 Remarks', sortable: true , thStyle:  {width: '8%'}},
                    
                    // { key: 'reject_comment', label: 'Reject Reason', sortable: true , thStyle:  {width: '8%'}},
                    { key: 'status', label: 'Status', sortable: false ,thClass:'alignCenter',tdClass: 'alignCenter', thStyle:  {width: '18%'}},
                    { key: 'actions', label: 'Action', sortable: false , thStyle:  {width: '16%'}},
                ],

                sites:[],
                siteData:''/*this.$route.params.site_id*/,      
                
                // b-table and pagination options
                items           : [],
                isSortDirDesc   : true,
                perPageOptions  : [10, 20, 50, 100],
                perPage         : 10,
                sortBy          : 'id',
                totalRecords    : 0,
                currentPage     : 1,
                searchQuery     : '',
                from            : null,
                to              : null, 
                // calendar vars 
                start:moment().tz('Asia/Singapore').toDate(),
                end:moment().tz('Asia/Singapore').toDate(),
                supervisorSign : {
                    image:null,
                    name:'',
                    type: 'supervisor',
                    default:'no',
                },
                id:null,

                tempSign : {
                    image:null,
                    name:'',
                },
                show_default:false,
                having_default:false,  
                approve_signature_time:null,
                approve_remark:'',
                checked_date:null, 
                comment:null,
                // select all vars
                selectAll:false,
                selected:[],
                vusers:[],
                vuser:'all',
            }
        },

        methods : {
            getAll(e){
              this.selected = [];        
              if (e) {
                var role = this.$store.getters.currentUser.role;
                var selected = [];
                this.items.forEach(function(item){
                  // if((item.level_user_status != null) && (item.level_user_status.role == role)){
                    selected.push(item._id);
                  // }
                })
                this.selected = selected;
              }
            },

            multipleDelete(){
              if (this.selected.length > 0) {
                this.decisionAlert('Are you sure you want to delete selected reports?')
                .then(result => {
                  if (result.value) {
                    return this.$store.dispatch(POST_API, {
                      data:{
                            id: this.selected,
                          },
                      api:"/api/delete-multiple-shs-reports",
                    })
                    .then(() => {
                        if (this.$store.getters.containsErrors) {
                            this.error_message = this.$store.getters.getErrors;
                        } else {
                          this.successAlert();
                          
                          this.selected = [];
                          this.selectAll = false;
                          Bus.$emit('counter_update');
                          this.filterTable();
                        }
                    });
                  }
                })
                .catch(err => {})
              }else{
                this.customAlert('Please select reports.', '', 'warning')
              }
            },

            dataList(){
                this.selectAll = false;
                this.selected  = [];

                var vuserObj = this.vusers.filter(item => { return item.key == this.vuser});

                return this.$store.dispatch(POST_API, {
                    data:{
                        page          : this.currentPage,
                        keyword       : this.searchQuery,
                        rowsPerPage   : this.perPage,
                        sortBy        : this.sortBy,
                        sortDirection : this.isSortDirDesc,
                        role          : this.$store.getters.currentUser.role,
                        om_sites      : this.$store.getters.currentUser.om_sites,
                        site          : this.siteData != '' ? this.siteData :null,
                        start         : moment(this.start).format('YYYY-MM-DD'),
                        end           : moment(this.end).format('YYYY-MM-DD'),
                        vuser         : vuserObj.length > 0 ? vuserObj[0] : null
                    },
                    api: '/api/staircase-hand-sanitizer-reports'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                    } else {
                        this.items          = this.$store.getters.getResults.data.docs;
                        this.totalRecords   = this.$store.getters.getResults.data.total;
                        this.perPage        = this.$store.getters.getResults.data.limit;
                        this.from           = this.$store.getters.getResults.data.from
                        this.to             = this.$store.getters.getResults.data.to

                        return this.items;
                    }
                });
            },

            filterTable(){
                this.$refs.refUserListTable.refresh();
            },

            variantColor(status){
                if (status == 'active') {
                    return 'success';
                }else{
                    return 'primary';
                }
            },
            
            download(pdf){
                window.open(pdf, '_blank');
            },

            allSites(){
                return this.$store.dispatch(POST_API, {
                    data:{
                    role:this.$store.getters.currentUser.role,
                    om_sites:this.$store.getters.currentUser.om_sites,
                    },
                    api: '/api/all-sites'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                    } else {
                        this.sites = this.$store.getters.getResults.data;
                        // if (this.sites.length == 1 ) {
                        //     this.siteData = this.sites[0]._id;
                        //     this.filterTable();
                        //     this.updateDateFormat();
                        // }
                        var obj = {
                            _id:'all-site',
                            site_name:'All Project Sites',
                            pdf_date_format: 'DD MMM YYYY',
                            pdf_time_format: 'HH:mm'
                        }
                        this.sites.unshift(obj);
                        
                        this.siteData = this.defaultSite(this.sites)._id;
                        this.filterTable();
                        this.updateDateFormat();
                        
                        return this.sites;
                    }
                });
            },

            // calendar functions
            updateStart(){
                this.end = moment(this.start).tz('Asia/Singapore').toDate();
                this.filterTable();
            },
            disabledDates(){
                if (this.start) {
                    return moment(this.start).format('YYYY-MM-DD');
                }else{
                    return moment().format('YYYY-MM-DD');
                }
            },
            deleteItem(id){
              var msg = 'Are you sure want to delete this record?';
              var message='Staircase Hand Sanitizer Report Deleted Successfully.';

              Swal.fire({
                        title: 'Please Confirm',
                        text: msg,
                        icon: 'warning',
                        position: 'top-center',
                        showCancelButton: true,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        confirmButtonText: 'Yes',
                        cancelButtonText: 'No'
                      })
                    .then(result => {
                      if (result.value) {
                          return this.$store.dispatch(POST_API, {
                            data:{
                                  id: id,
                                },
                            api:"/api/delete-staircase-hand-sanitizer-report",
                              })
                              .then(() => {
                                  if (this.$store.getters.containsErrors) {
                                      this.error_message = this.$store.getters.getErrors;
                                      this.showAlert();
                                  } else {
                                    
                                    Swal.fire({
                                      position: 'center',
                                      icon: 'success',
                                      title: message,
                                      showConfirmButton: false,
                                      timer: 1500
                                    })
                                    Bus.$emit('counter_update');
                                    this.$refs.refUserListTable.refresh();
                                  }
                              });
                      }
                  })
                  .catch(err => {              
                  })
            },

            openSignature(id, date){
            
                if (this.having_default == true) {
                    this.show_default = true;
                }else{
                    this.show_default = false;
                }

                this.checked_date = date;
                this.approve_signature_time = moment(new Date()).tz('Asia/Singapore').format('DD MMM YYYY HH:mm');
                this.approve_remark = '';
                this.id = id; 
                this.$refs['signatureModel'].show();
            },
           
            clearSignature(){
                this.$refs.signaturePad.clearSignature();
            },

            saveSignature(){
                const { isEmpty, data } = this.$refs.signaturePad.saveSignature();
                if (isEmpty) {
                    Swal.fire({
                        position: 'center',
                        icon: 'warning',
                        title: 'Signature is required',
                        showConfirmButton: false,
                        timer: 1500
                    })
                }else{
                    this.supervisorSign.image = data;
                    this.supervisorSign.name = 'signature.png';
                    this.supervisorSign.type =  'supervisor';
                    this.supervisorSign.default = 'no';

                    this.approveReport(this.supervisorSign);
                    this.$refs['signatureModel'].hide();
                }
            },
            useDefault(){

              return this.$store.dispatch(POST_API, {
                   data:{
                     id:this.$store.getters.currentUser._id
                   },
                   api: '/api/get-default-signature'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        this.showDismissibleAlert = true;
                        window.scrollTo(0,0);
                    } else {
                        this.showDismissibleAlert = false;
                        var data  = this.$store.getters.getResults.data;
                        
                        if (data == null || data.default_signature == null) {
                          
                          this.having_default = false;
                          
                        }else{

                          this.tempSign = {
                            image : data.default_signature,
                            name : 'siganture.png',
                          }

                          this.having_default = true;
                        }
                    }
                });
            },
            saveDefault(){
                this.supervisorSign = {
                  image : this.tempSign.image,
                  name : 'signature.png',
                  type :   'supervisor',
                  default:'yes'
                }
                this.approveReport(this.supervisorSign);
                this.$refs['signatureModel'].hide();
            },
            hideSignature(){
              this.openedSignModel = null;
            },
            
            approveReport(sign){

                return this.$store.dispatch(POST_API, {
                    data:{
                        crew_signature:sign,
                        id:this.id,
                        role:this.$store.getters.currentUser.role,
                        approve_signature_time:this.approve_signature_time,
                        approve_remark:this.approve_remark,
                    },
                    api: '/api/approve-staircase-hand-sanitizer-report'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        /*this.showDismissibleAlert = true;
                        window.scrollTo(0,0);*/
                        this.errorAlertCF();
                        
                    } else {
                        this.showDismissibleAlert = false;
                        var data  = this.$store.getters.getResults.data;
                        
                        this.successAlert();
                        this.$refs.refUserListTable.refresh();

                        Bus.$emit('counter_update');
                        window.open(data,'_blank');
                    }
                });
            },
            breadCrumb(){
              var item = [{
                to:{name:'client-dashboard'},
                text: 'Dashboard',
              },{
                to:null,
                text: 'Reports',
              },{
                to:{name:'custom-forms-dashboard'},
                text: 'Form Dashboard',
              },{
                to:{name:'custom-forms-reports'},
                text:'Custom Forms'
              },{
                to:null,
                text:'Staircase Hand Sanitizer',
                active:true
              }];
              return this.filterReportBreadCrum(item);
            },
            updateDateFormat(){
              if (this.siteData != '') {
                  this.sites.forEach(item => {
                    if (item._id == this.siteData) {
                        this.date_format = item.pdf_date_format;
                        this.time_format = item.pdf_time_format; 
                    }
                  })

              }else{
                  this.date_format = 'DD MMM YYYY';
                  this.time_format = 'HH:mm';
              }
            },
            disabledDate(date){
              return date < moment(new Date(this.checked_date)).subtract(1,'days').tz('Asia/Singapore');
            },
            openRejectBox(item){
              this.showDismissibleAlert = false;
              this.id = item._id;
              this.comment = item.reject_comment; 
              this.$refs['rejectbox'].show();
            },
            markReject(e){
              e.preventDefault();

              return this.$store.dispatch(POST_API, {
                 data:{
                   comment:this.comment,
                   id:this.id,
                   role:this.$store.getters.currentUser.role
                 },
                 api: '/api/reject-shs-report'
              })
              .then(() => {
                  if (this.$store.getters.containsErrors) {
                      this.error_message = this.$store.getters.getErrors;
                      this.showDismissibleAlert = true;
                  } else {
                      this.showDismissibleAlert = false;
                      var message  = this.$store.getters.getResults.message;
                      Swal.fire({
                        position: 'center',
                        icon: 'success',
                        title: message,
                        showConfirmButton: false,
                        timer: 1500
                      }).then((result) => {
                          this.filterTable();
                          Bus.$emit('counter_update');
                          this.$refs['rejectbox'].hide();
                      });

                      
                  }
              });
            },
            allowReject(item){
              var role = this.$store.getters.currentUser.role;

              if (item.reject_to == role && ((item.level_one_user_role != role) && (item.level_two_user_role != role) && (item.level_three_user_role != role))) {          
                return false;        
              } else if (item.reject_by == role){
                return false;
              } else if ( (item.reject_behalf == 'administrator') && (role == 'administrator') ){
                return false;
              } else if ((item.level_user_status != null) && (item.level_user_status.role == '')){
                return false;
              } else {
                return true;
              }
            },
            getBuilding(item){
              var arr = [];

              if (item.length > 0) {

                arr = item.map(t => { return t.building_name });
              }

              arr = arr.join(', ');
              return arr;
            },

            zipDownload(){
              var organization  = this.$store.getters.currentUser.organization;
              var id            = this.selected && this.selected.length > 0 ? this.selected.toString() : [];
              var start         = moment(this.start).format('YYYY-MM-DD');
              var end           = moment(this.end).format('YYYY-MM-DD');
              
              var params  = "organization="+organization+"&id="+id+"&start="+start+"&end="+end;
              var baseurl = "";

              baseurl        = process.env.VUE_APP_SERVER_URL + '/api/shs-zip-report?' + params;
              this.selectAll = false,
              this.selected  = []
              
              window.open(baseurl,'_blank');
            },

            getValidateUser(){

              return this.$store.dispatch(POST_API, {
                data:{
                  form: 'Staircase Hand Sanitizer'
                },
                api: '/api/get-validate-user-by-form-name'
              })
              .then(() => {
                  if (this.$store.getters.containsErrors) {
                      this.error_message = this.$store.getters.getErrors;
                      this.showDismissibleAlert = true;
                  } else {
                      this.showDismissibleAlert = false;
                      this.vusers  = this.$store.getters.getResults.data;
                      return this.vusers; 
                  }
              });
            }

            // zipDownload(){
            //   return this.$store.dispatch(POST_API, {
            //     data:{
            //           keyword       : this.searchQuery,
            //           sortBy        : this.sortBy,
            //           sortDirection : this.isSortDirDesc,
            //           role          : this.$store.getters.currentUser.role,
            //           om_sites      : this.$store.getters.currentUser.om_sites,
            //           site          : this.siteData != '' ? this.siteData :null,
            //           start         : moment(this.start).format('YYYY-MM-DD'),
            //           end           : moment(this.end).format('YYYY-MM-DD'),
            //           id            : this.selected,
            //         },
            //     api:"/api/shs-zip-report",
            //   })
            //   .then(() => {
            //       if (this.$store.getters.containsErrors) {
            //           this.error_message = this.$store.getters.getErrors;
            //           this.errorAlert();
            //       } else {
            //           this.selectAll = false,
            //           this.selected  = []
                      
            //           var data = this.$store.getters.getResults.data;

            //           if (data) {
                        
            //             var link = document.createElement("a");
            //             link.download = data.name;
            //             link.href = data.file;
            //             document.body.appendChild(link);
            //             link.click();
            //             document.body.removeChild(link);
            //             //delete link;
            //           }                   
                    
            //       }
            //   });
            // }
        },
        mounted(){
            this.useDefault();
            this.allSites();
            this.getValidateUser();
        }
    }
</script>

<style lang="scss" scoped>
    .per-page-selector {
        width: 90px;
    }
</style>

<style lang="scss">
    @import '@core/scss/vue/libs/vue-select.scss';
</style>
